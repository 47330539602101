import {
  FloatingWrapper,
  MentionAtomNodeAttributes,
  useMentionAtom,
} from '@remirror/react'
import cn from 'classnames'
import React, { useState } from 'react'
import { User } from 'store/modules/users'
import { Avatar } from 'src/design-system'
import { avatarProps } from 'app/packs/src/utils/user-helpers'

export type MentionDropdownProps = {
  users: User[]
}

export const MentionDropdown = ({ users }: MentionDropdownProps) => {
  const [items, setItems] = useState<Array<MentionAtomNodeAttributes & User>>(
    []
  )
  const { state, getMenuProps, getItemProps, indexIsHovered, indexIsSelected } =
    useMentionAtom({ items })

  const enabled = !!state

  React.useEffect(() => {
    if (!state) return
    const query = state.query.full.toLowerCase()
    const filtered = users
      .filter((user) => user.fullName.toLowerCase().includes(query))
      .slice(0, 5)
      .map((user) => ({ ...user, label: user.fullName }))

    setItems(filtered)
  }, [state, users])

  return (
    <FloatingWrapper
      containerClass="z-popover"
      enabled={enabled}
      placement="bottom-start"
      positioner="cursor"
      renderOutsideEditor
    >
      <div
        {...getMenuProps()}
        className="rounded-lg bg-white shadow-md border border-gray-50 animate-fade-in flex flex-col"
      >
        {items.map((item, index) => {
          const isHighlighted = indexIsSelected(index)
          const isHovered = indexIsHovered(index)
          return (
            <div
              key={item.id}
              className={cn('flex gap-3 p-2', {
                'bg-gray-100': isHighlighted,
                'hover:bg-gray-100': !isHighlighted && isHovered,
              })}
              {...getItemProps({ item, index })}
            >
              <Avatar {...avatarProps(item)} size="xs" />
              {item.label}
            </div>
          )
        })}
      </div>
    </FloatingWrapper>
  )
}
