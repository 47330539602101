import {
  EditorComponent,
  OnChangeHTML,
  Remirror,
  useRemirror,
} from '@remirror/react'
import cn from 'classnames'
import React, {
  ComponentPropsWithoutRef,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import 'remirror/styles/core.css'
import 'remirror/styles/extension-blockquote.css'
import 'remirror/styles/extension-code-block.css'
import 'remirror/styles/extension-count.css'
import 'remirror/styles/extension-emoji.css'
import 'remirror/styles/extension-gap-cursor.css'
import 'remirror/styles/extension-image.css'
import 'remirror/styles/extension-list.css'
import 'remirror/styles/extension-node-formatting.css'
import 'remirror/styles/extension-placeholder.css'
import 'remirror/styles/extension-positioner.css'
import 'remirror/styles/extension-whitespace.css'
import { EditorContext, useEditorContext } from './context'
import styles from './editor.module.scss'
import { EventEmitter } from './event-emitter'
import { createExtensions } from './extensions'
import { Menu } from './menu'
import { User } from 'store/modules/users'
import { MentionDropdown } from './mention-dropdown'
import { Node } from '@remirror/pm/model'

export type { EditorContext }

export type EditorProps = Omit<
  ComponentPropsWithoutRef<'div'>,
  'onChange' | 'children' | 'onBlur' | 'onFocus'
> & {
  onChange?: (value: string, data?: { users?: User[] }) => void
  disabled?: boolean
  initialContent?: string
  onBlur?: () => void
  onFocus?: (html?: string) => void
  onSave?: () => void
  placeholder?: string
  autoFocus?: boolean
  name?: string
  rows?: number
  invalid?: boolean
  fixHeight?: boolean
  textSize?: 'xs' | 'sm' | 'base'
  taggableUsers?: User[]
}

const BrowserEditor = forwardRef<EditorContext, EditorProps>(
  (
    {
      onChange,
      disabled,
      initialContent,
      onBlur,
      onFocus,
      onSave,
      className,
      placeholder,
      onClick,
      autoFocus,
      rows,
      name,
      invalid,
      fixHeight = false,
      textSize = 'sm',
      style = {},
      taggableUsers,
      ...props
    },
    ref
  ) => {
    const [users, setUsers] = useState<User[]>([])
    const enableTagging = typeof taggableUsers !== 'undefined'
    const extensions = useMemo(
      () => createExtensions({ placeholder, tagging: enableTagging }),
      [placeholder, enableTagging]
    )
    const { manager, state } = useRemirror({
      extensions: () => extensions,
      content: initialContent,
      stringHandler: 'html',
    })
    const context = useEditorContext(ref, manager)
    const [html, setHtml] = useState(initialContent)
    const menuRef = useRef<HTMLDivElement>(null)

    const processAndSetHtml = (raw: string) => {
      const html = raw === '<p></p>' ? '' : raw
      setHtml(html)
      onChange?.(html, { users })
    }

    const extractUsers = React.useCallback(
      (doc: Node) => {
        const users = new Set<User>()
        doc.descendants((node) => {
          if (node.type.name === 'mentionAtom' && node.attrs.name === 'user') {
            const id = node.attrs.id
            const user = taggableUsers?.find((u) => u.id === id)
            if (user) users.add(user)
          }
        })
        return [...users.values()]
      },
      [taggableUsers]
    )

    return (
      <div
        className={cn(
          className,
          'remirror-theme editor',
          fixHeight && styles['fixed-height'],
          styles[`textSize-${textSize}`],
          styles.editor,
          invalid && styles.invalid
        )}
        onClick={(e) => {
          onClick?.(e)
          context.focus()
        }}
        style={
          {
            ...style,
            minHeight: rows ? `${rows * 1.5}rem` : undefined,
          } as React.CSSProperties
        }
        {...props}
      >
        {name && (
          <input
            type="hidden"
            name={name}
            value={html ?? ''}
            onChange={(e) => processAndSetHtml(e.target.value)}
          />
        )}
        <Remirror
          manager={manager}
          initialContent={state}
          editable={!disabled}
          autoFocus={autoFocus}
          onChange={({ state }) => {
            setUsers(extractUsers(state.doc))
          }}
        >
          {!disabled && (
            <>
              <Menu ref={menuRef} />
              <OnChangeHTML onChange={processAndSetHtml} />
              {taggableUsers?.length && (
                <MentionDropdown users={taggableUsers} />
              )}
            </>
          )}
          <EventEmitter
            onBlur={(e) => {
              if (menuRef.current && menuRef.current.contains(e.relatedTarget))
                return
              onBlur?.()
            }}
            onFocus={() => onFocus?.(html)}
            onSave={onSave}
          />
          <EditorComponent />
        </Remirror>
      </div>
    )
  }
)

/**
 * A component which wraps the rich text editor and only renders
 * it in the browser, to avoid SSR issues.
 **/
export const Editor = forwardRef<EditorContext, EditorProps>((props, ref) => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])
  if (!mounted) return null

  return <BrowserEditor {...props} ref={ref} />
})

BrowserEditor.displayName = 'BrowserEditor'
Editor.displayName = 'Editor'
