import { ForwardedRef, useImperativeHandle, useMemo } from 'react'
import { AnyRemirrorManager, RemirrorJSON } from 'remirror'

export type EditorContext = {
  reset: (content?: RemirrorJSON) => void
  focus: () => void
}

// Context allowing external components to access certain editor methods
export const useEditorContext = (
  ref: ForwardedRef<EditorContext>,
  manager: AnyRemirrorManager
) => {
  const context = useMemo<EditorContext>(
    () => ({
      reset(content) {
        const emptyState = manager.createState({ content })
        manager.view.updateState(emptyState)
      },
      focus() {
        manager.view.focus()
      },
    }),
    [manager]
  )
  useImperativeHandle(ref, () => context)
  return context
}
