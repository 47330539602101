import {
  FloatingToolbar,
  useActive,
  useAttrs,
  useChainedCommands,
} from '@remirror/react'
import cn from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import {
  ArrowSquareOut,
  Code,
  IconProps,
  Link,
  ListBullets,
  ListChecks,
  ListNumbers,
  Quotes,
  TextBolder,
  TextItalic,
  X,
} from '@phosphor-icons/react'
import React, {
  ComponentPropsWithoutRef,
  FC,
  forwardRef,
  useState,
} from 'react'

type MenuButtonProps = ComponentPropsWithoutRef<'button'> & {
  active?: boolean
  icon: FC<IconProps>
  label: string
}

const MenuButton: FC<MenuButtonProps> = ({
  icon: Icon,
  active,
  onClick,
  label,
  ...props
}) => (
  <button
    type="button"
    title={label}
    className={cn(
      'w-8 h-8 text-gray-400 rounded-md hover:bg-gray-100 transition-all flex justify-center items-center',
      {
        'text-gray-900': active,
      }
    )}
    aria-pressed={active}
    onClick={(e) => {
      e.preventDefault()
      onClick?.(e)
    }}
    {...props}
  >
    <Icon size={18} />
  </button>
)

const LinkForm: FC<{
  onSubmit: (url: string | undefined) => void
  onRemove: () => void
}> = ({ onRemove, onSubmit }) => {
  const { link } = useAttrs()
  const existing = link()?.href as string | undefined
  const [url, setUrl] = useState(existing ?? '')
  return (
    <motion.div
      className="absolute inset-0 bg-white items-center p-0 flex"
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.95 }}
    >
      <input
        type="text"
        autoFocus
        className="border-0 p-1 text-sm focus:ring-0 focus:outline-none w-full"
        placeholder="Enter URL..."
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            onSubmit(url)
            e.preventDefault()
          }
          if (e.key === 'Escape') onSubmit(existing)
        }}
      />
      {existing && (
        <a
          href={existing}
          target="_blank"
          rel="noreferrer"
          className="ml-2 text-gray-400 hover:text-gray-500 transition-all"
        >
          <ArrowSquareOut size={16} />
        </a>
      )}
      <button
        type="button"
        className="text-gray-400 hover:text-gray-500 transition-all px-2"
        onClick={onRemove}
      >
        <X size={16} />
      </button>
    </motion.div>
  )
}

const Separator = () => <div className="w-px h-4 mx-2 bg-gray-400" />

export const Menu = forwardRef<HTMLDivElement>((_props, ref) => {
  const chain = useChainedCommands()
  const active = useActive()
  const [linkActive, setLinkActive] = useState(false)

  return (
    <FloatingToolbar
      positioner="selection"
      placement="top"
      className="p-1 rounded-lg bg-white shadow-md border-[1px] border-gray-100 animate-fade-in z-popover"
      disablePortal
    >
      <div className="flex items-center" ref={ref}>
        <MenuButton
          onClick={() => chain.toggleBold().focus().run()}
          active={active.bold()}
          icon={TextBolder}
          label="Bold"
        />
        <MenuButton
          onClick={() => chain.toggleItalic().focus().run()}
          active={active.italic()}
          icon={TextItalic}
          label="Italic"
        />
        <MenuButton
          onClick={() => chain.toggleCode().focus().run()}
          active={active.code()}
          icon={Code}
          label="Code"
        />
        <MenuButton
          onClick={() => setLinkActive(true)}
          active={active.link()}
          icon={Link}
          label="Link"
        />
        <MenuButton
          onClick={() => chain.toggleBlockquote().focus().run()}
          active={active.blockquote()}
          icon={Quotes}
          label="Blockquote"
        />
        <Separator />
        <MenuButton
          onClick={() => chain.toggleBulletList().focus().run()}
          active={active.bulletList()}
          icon={ListBullets}
          label="Bullet list"
        />
        <MenuButton
          onClick={() => chain.toggleOrderedList().focus().run()}
          active={active.orderedList()}
          icon={ListNumbers}
          label="Ordered list"
        />
        <MenuButton
          onClick={() => chain.toggleTaskList().focus().run()}
          active={active.taskList()}
          icon={ListChecks}
          label="Task list"
        />
      </div>
      <AnimatePresence>
        {linkActive && (
          <LinkForm
            onRemove={() => {
              setLinkActive(false)
              chain.removeLink().focus().run()
            }}
            onSubmit={(url) => {
              setLinkActive(false)
              if (url) chain.updateLink({ href: url }).focus().run()
            }}
          />
        )}
      </AnimatePresence>
    </FloatingToolbar>
  )
})

Menu.displayName = 'Menu'
