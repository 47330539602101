import { AnyExtension } from 'remirror'
import {
  BlockquoteExtension,
  BoldExtension,
  BulletListExtension,
  CodeExtension,
  HardBreakExtension,
  ItalicExtension,
  LinkExtension,
  MentionAtomExtension,
  OrderedListExtension,
  PlaceholderExtension,
  TaskListExtension,
} from 'remirror/extensions'

type ExtensionsOptions = {
  placeholder?: string
  tagging?: boolean
}

export const createExtensions = ({
  placeholder = 'What did you work on today?',
  tagging = false,
}: ExtensionsOptions) => {
  const extensions: AnyExtension[] = [
    new BoldExtension({}),
    new ItalicExtension(),
    new CodeExtension(),
    new LinkExtension({
      autoLink: true,
      selectTextOnClick: true,
      supportedTargets: ['_blank'],
      defaultTarget: '_blank',
    }),
    new BulletListExtension({}),
    new OrderedListExtension(),
    new TaskListExtension(),
    new HardBreakExtension(),
    new BlockquoteExtension(),
    new PlaceholderExtension({ placeholder }),
  ]

  if (tagging) {
    extensions.push(
      new MentionAtomExtension({
        matchers: [{ name: 'user', char: '@', matchOffset: 0 }],
        selectable: false,
        // TODO: Figure out why this breaks parsing the HTML
        // mentionTag: 'a',
        // extraAttributes: {
        //   href: ({ attrs }) =>
        //     new URL(`/users/${attrs.id}`, window.location.origin).toString(),
        // },
      })
    )
  }

  return extensions
}
