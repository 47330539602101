import {
  useCommands,
  useEditorDomRef,
  useEditorEvent,
  useKeymap,
} from '@remirror/react'
import { FC, FocusEvent } from 'react'

export const EventEmitter: FC<{
  onBlur?: (e: FocusEvent<HTMLElement>) => void
  onFocus?: (e: FocusEvent<HTMLElement>) => void
  onSave?: () => void
}> = ({ onBlur, onFocus, onSave }) => {
  const { blur } = useCommands()
  const editorRef = useEditorDomRef()

  useEditorEvent('blur', (e) =>
    onBlur?.(e as unknown as FocusEvent<HTMLElement>)
  )
  useEditorEvent('focus', (e) =>
    onFocus?.(e as unknown as FocusEvent<HTMLElement>)
  )

  useKeymap('Mod-Enter', () => {
    const form = editorRef.current?.closest('form')
    if (form) form.requestSubmit()
    onSave?.()
    blur()
    return true
  })

  useKeymap('Escape', () => {
    blur()
    return true
  })

  return null
}
